import React, { useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import ReactMarkdown from "react-markdown";

import Layout from "../components/Layout";
import DishCard from "../components/DishCard";
import { Box, Grid, Tab, Tabs, Typography, Paper } from "@material-ui/core";

import "./scss/restaurant.scss";
import ArticleSmallCard from "../components/ArticleSmallCard";

export const query = graphql`
  query RestaurantQuery($id: String) {
    strapiRestaurant(strapiId: { eq: $id }) {
      strapiId
      name
      description
      mainImage {
        publicURL
      }
      articles {
        body
        mainImage {
          absolutePath
          childImageSharp {
            resize(cropFocus: CENTER, height: 400, width: 400) {
              src
            }
          }
        }
        title
        slug
      }
    }
  }
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      className="tab-container"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export default function Restaurant({ data }) {
  const restaurant = data.strapiRestaurant;
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  const dishNames = [
    "Pho",
    "Pad Thai",
    "Larb Thai",
    "Bulgogi",
    "Kalbi",
    "Beef Stew",
    "Sashimi",
    "Curry",
    "Wonton Noodle Soup",
  ];

  const descriptions = [
    "Moo shu-wrapped fried Lechon, cucumber, grilled green onions, Mang Tomás-based sauce, side of purple cabbage slaw",
    "Green apple, pickled red onions, green onions, peanuts, fried shallots, herbs, nuoc mam",
    "Sun Ramen Noodles, spicy curry & coconut milk tare, grilled shrimp, fried silken tofu, roasted tomatoes, red cabbage, green onions",
    "Sushi-grade ahi tuna, cucumbers, avocado, green onions, green apples, yellow onions, rice, poke sauce, cashew aioli, pickled radish, furikake, nori, sesame seeds",
  ];

  const dishes = [];

  for (let i = 1; i < 5; i++) {
    dishes.push({
      imageSrc: `https://loremflickr.com/60${i}/40${i}/asianfood`,
      name: dishNames[getRandomInt(9)],
      description: descriptions[getRandomInt(4)],
    });
  }

  const photos = [];

  for (let i = 1; i < 5; i++) {
    photos.push({
      imageSrc: `https://loremflickr.com/60${i}/40${i}/${restaurant.name
        .toLowerCase()
        .replace(/\s/g, "-")}`,
      name: dishNames[getRandomInt(9)],
    });
  }

  return (
    <Layout>
      <div>
        <Grid container>
          <Grid item xs={12}>
            <img
              src={
                restaurant.mainImage
                  ? restaurant.mainImage.publicURL
                  : "https://loremflickr.com/600/400/asianfood"
              }
              alt="[NEED TO CHANGE]"
              className="main-image"
            />
          </Grid>
          <Grid item xs={12}>
            <div className="restaurant-info">
              <Typography component="h1" variant="h4">
                {restaurant.name}
              </Typography>
              <ReactMarkdown source={restaurant.description} />
            </div>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab label="Known For" {...a11yProps(0)} />
                <Tab label="Photos" {...a11yProps(1)} />
                <Tab label="Articles" {...a11yProps(2)} />
              </Tabs>
            </Paper>
            <TabPanel value={value} index={0}>
              {dishes.map((d, dIndex) => {
                return (
                  <DishCard
                    key={dIndex}
                    imageSrc={d.imageSrc}
                    name={d.name}
                    description={d.description}
                  />
                );
              })}
            </TabPanel>
            <TabPanel value={value} index={1}>
              {photos.map((p, pIndex) => {
                return (
                  <img
                    key={pIndex}
                    src={p.imageSrc}
                    alt={p.name}
                    className="restasurant-photo"
                  />
                );
              })}
            </TabPanel>
            <TabPanel value={value} index={2}>
              {restaurant.articles.map((a, aIndex) => {
                return (
                  <ArticleSmallCard
                    key={aIndex}
                    slug={a.slug}
                    title={a.title}
                    imageSrc={a.mainImage.childImageSharp.resize.src}
                  />
                );
              })}
              {restaurant.articles.length === 0 && <p>No articles yet</p>}
            </TabPanel>
          </Grid>
        </Grid>
      </div>
    </Layout>
  );
}

Restaurant.propTypes = {
  data: PropTypes.object,
};
