import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@material-ui/core";

import "./scss/dishcard.scss";

export default function DishCard({ imageSrc, name, description }) {
  return (
    <Grid container className="dish-card">
      <Grid item xs={12}>
        <img src={imageSrc} alt={name} className="main-image" />
      </Grid>
      <Grid item xs={12}>
        <div className="content-container">
          <Typography component="h2" variant="subtitle1" align="center">
            {name}
          </Typography>
          {description && (
            <Typography component="p" variant="body2">
              {description}
            </Typography>
          )}
        </div>
      </Grid>
    </Grid>
  );
}

DishCard.propTypes = {
  imageSrc: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
};
